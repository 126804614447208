import React from 'react';
import { motion } from 'framer-motion';
import { Lightbulb, Shield, Users, Award, ChevronRight } from 'lucide-react';

const fadeIn = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

const ValueCard = ({ icon: Icon, value }) => (
  <motion.div 
    className="flex items-center space-x-3 bg-gradient-to-r from-purple-800 to-indigo-800 p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon className="text-purple-300" size={24} />
    <span className="text-lg font-semibold text-white">{value}</span>
  </motion.div>
);

const TimelineItem = ({ year, event }) => (
  <motion.div 
    className="flex items-start space-x-6 mb-8"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="w-24 h-24 bg-purple-600 rounded-full flex items-center justify-center text-xl font-bold flex-shrink-0">
      <span className="text-white text-center">{year}</span>
    </div>
    <div className="flex-1 bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg shadow-lg mt-2">
      <p className="text-lg text-gray-200">{event}</p>
    </div>
  </motion.div>
);

const About = () => {
  return (
    <main className="container mx-auto px-4 py-24">
      <motion.h2 
        className="text-5xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        About TUGADOT
      </motion.h2>

      <motion.div 
        className="bg-gradient-to-br from-purple-900 to-indigo-900 p-8 rounded-lg mb-12 shadow-lg"
        {...fadeIn}
      >
        <p className="mb-4 text-lg text-gray-200">TUGADOT is a cutting-edge software agency poised at the forefront of technological innovation. Founded in 2024, we're on a mission to establish ourselves as leaders in AI integration and custom software development.</p>
        <p className="mb-4 text-lg text-gray-200">Our goal is to empower businesses with transformative technology solutions that drive growth, efficiency, and innovation. We believe in the power of AI and emerging technologies to reshape industries and create new possibilities.</p>
        <p className="text-lg text-gray-200">With a team of passionate technologists, data scientists, and creative problem-solvers, we're ready to tackle complex challenges and turn visionary ideas into reality. Our commitment to excellence and continuous learning ensures that we'll always stay ahead of the curve in the fast-paced world of technology.</p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
        <motion.div className="bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg text-center shadow-lg" {...fadeIn}>
          <h3 className="text-2xl font-semibold mb-4 text-white">Our Vision</h3>
          <p className="text-gray-300">To be the catalyst for technological advancement, driving innovation across industries and improving lives through intelligent solutions.</p>
        </motion.div>

        <motion.div className="bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg shadow-lg" {...fadeIn} transition={{ delay: 0.2 }}>
          <h3 className="text-2xl font-semibold mb-4 text-center text-white">Our Values</h3>
          <div className="grid grid-cols-2 gap-4">
            <ValueCard icon={Lightbulb} value="Innovation" />
            <ValueCard icon={Shield} value="Integrity" />
            <ValueCard icon={Users} value="Collaboration" />
            <ValueCard icon={Award} value="Excellence" />
          </div>
        </motion.div>

        <motion.div className="bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg text-center shadow-lg" {...fadeIn} transition={{ delay: 0.4 }}>
          <h3 className="text-2xl font-semibold mb-4 text-white">Our Approach</h3>
          <p className="text-gray-300">We combine deep technical expertise with a human-centered design approach to deliver solutions that are not just powerful, but also intuitive and user-friendly.</p>
        </motion.div>
      </div>

      <motion.div 
        className="mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <h3 className="text-3xl font-semibold mb-8 text-center text-white">Our Journey</h3>
        <div className="max-w-3xl mx-auto">
          <TimelineItem year="2024" event="TUGADOT founded with a vision to revolutionize AI integration" />
          <TimelineItem year="Q2 2024" event="Launch of our first AI consultancy services" />
          <TimelineItem year="Q3 2024" event="Expansion of our team and partnership initiatives" />
          <TimelineItem year="Q4 2024" event="Development of our proprietary AI integration framework" />
          <TimelineItem year="2025" event="Ambitious plans to shape the future of technology" />
        </div>
      </motion.div>

      <motion.div 
        className="text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <h3 className="text-2xl font-semibold mb-4 text-white">Ready to innovate with us?</h3>
        <motion.button
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full transition-colors flex items-center mx-auto"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Get in Touch
          <ChevronRight className="ml-2" />
        </motion.button>
      </motion.div>
    </main>
  );
};

export default About;