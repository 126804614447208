import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Code, Cpu, Zap } from 'lucide-react';

const Home = () => (
  <main className="container mx-auto px-4 py-24">
    <section className="text-center mb-16">
      <h2 className="text-5xl font-bold mb-4">Shaping the Future with AI</h2>
      <p className="text-xl mb-8">Innovative software solutions powered by cutting-edge technology</p>
      <Link to="/contact" className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full transition-colors inline-flex items-center">
        Get Started
        <ChevronRight className="ml-2" />
      </Link>
    </section>

    <section className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
      <div className="bg-white bg-opacity-10 p-6 rounded-lg">
        <Cpu className="text-purple-400 mb-4" size={48} />
        <h3 className="text-2xl font-semibold mb-2">AI Integration</h3>
        <p>Harness the power of artificial intelligence to transform your business processes.</p>
      </div>
      <div className="bg-white bg-opacity-10 p-6 rounded-lg">
        <Code className="text-purple-400 mb-4" size={48} />
        <h3 className="text-2xl font-semibold mb-2">Custom Software</h3>
        <p>Tailor-made solutions designed to meet your unique business needs.</p>
      </div>
      <div className="bg-white bg-opacity-10 p-6 rounded-lg">
        <Zap className="text-purple-400 mb-4" size={48} />
        <h3 className="text-2xl font-semibold mb-2">Rapid Prototyping</h3>
        <p>Quick idea validation and development to keep you ahead of the competition.</p>
      </div>
    </section>

    <section className="text-center">
      <h2 className="text-4xl font-bold mb-4">Ready to Innovate?</h2>
      <p className="text-xl mb-8">Let's create the future together.</p>
      <Link to="/contact" className="bg-transparent border-2 border-purple-400 text-purple-400 hover:bg-purple-400 hover:text-white font-bold py-3 px-6 rounded-full transition-colors">
        Contact Us
      </Link>
    </section>
  </main>
);

export default Home;